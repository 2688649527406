body,
html {
  font-size: 16px !important;
}

#root {
  height: 100%;
}

.showScrollBar::-webkit-scrollbar {
  width: 6px;
  background: transparent !important;
  height: 6px;
}
.showScrollBarLight::-webkit-scrollbar {
  width: 6px;
  background: transparent !important;
}
.hideScrollBar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.noScrollBar::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.noScrollBar {
  scrollbar-width: none; /* Firefox implementation */
}
.showScrollBarLight::-webkit-scrollbar-thumb {
  background: #bfc1cd;
  border-radius: 10px;
}
.showScrollBar::-webkit-scrollbar-thumb {
  background: #aaadbd;
  border-radius: 10px;
  margin: 0 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #7f849c;
}
.hideScrollBar::-webkit-scrollbar-thumb {
  background: transparent;
}

.noScrollBar::-webkit-scrollbar-thumb {
  background: transparent;
}
::-webkit-scrollbar-track {
  background: transparent;
  margin: 4px 0;
}
input[type='time']::-webkit-calendar-picker-indicator {
  cursor: pointer;
}
* {
  font-family: 'Inter', sans-serif;
}

@import '~react-toastify/dist/ReactToastify.min';
